import { DEL_ITEM_PERMISSION, MAINTAIN_PERMISSION, WEB_ANALYTICS_PPV_COST_BUCKETS } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Maintain List of Cost Buckets", "has-active": false, "has-filter-inactive": false, "create-button-text": "Add New Cost Bucket" }, scopedSlots: _vm._u([{ key: "customFilter", fn: function() {
    return [_c("span", [_vm._v(" Cost Buckets set up here are used to calculate the Procurement Standard Cost (StdCostProcmt) and affects the calculation of PPVProcmt. ")])];
  }, proxy: true }, { key: "action", fn: function(ref) {
    var edit = ref.edit;
    var record = ref.record;
    return [_vm.can(_vm.permissions.maintain) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Edit")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "mr-2", attrs: { "icon": "edit", "size": "small", "type": "link" }, on: { "click": function($event) {
      return edit(record);
    } } })], 1) : _vm._e(), _vm.can(_vm.permissions.del) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Delete")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "delete", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.onDel(record);
    } } })], 1) : _vm._e()];
  } }]) }, [_c("text-field", { key: "CostBucket", attrs: { "data-index": "costBucket", "title": "Cost Bucket" } }), _c("text-field", { key: "BucketLongDesc", attrs: { "data-index": "bucketLongDesc", "title": "Cost Bucket Description" } }), _c("check-box-field", { key: "IncludeinPPV", attrs: { "data-index": "includeinPPV", "title": "Include In StdCostProcmt", "align": "center", "width": 200 } })], 1);
};
var staticRenderFns$1 = [];
var CostBuckets_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => "")();
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "CostBuckets",
  inject: ["crud", "can"],
  data() {
    return {
      apiUrl: apiUrl$1,
      permissions: {
        del: DEL_ITEM_PERMISSION,
        maintain: MAINTAIN_PERMISSION
      }
    };
  },
  methods: {
    onDel(record) {
      this.$confirm({
        class: "list__modal-delete",
        icon: "delete",
        title: `Do you want to delete this Cost Bucket`,
        content: `If you click Yes, you won\u2019t be able to undo this Delete operation. Are you sure you want delete this Cost Bucket?`,
        cancelText: "No",
        okText: "Yes",
        okType: "danger",
        onOk: this.onConfirmDelete(record)
      });
    },
    onConfirmDelete(record) {
      return () => {
        this.axios.put(`${this.apiUrl}/web-analytics/cost-buckets`, {
          CostBucket: record.costBucket,
          IncludeinPPV: record.includeinPPV,
          UpdateType: "delete"
        }).then(() => {
          this.crud.fetchList();
          this.$notification["success"]({
            message: "Item Deleted!"
          });
        }).catch(() => {
          this.$notification["error"]({
            message: "Something went wrong, please try again!"
          });
        });
      };
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "6551d128", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CostBuckets = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "web-analytics.cost-buckets", "edit-route": "/web-analytics/cost-buckets/:id", "create-route": "/web-analytics/cost-buckets/create", "page": _vm.page } }, [_c("cost-buckets")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    CostBuckets
  },
  data() {
    return {
      page: WEB_ANALYTICS_PPV_COST_BUCKETS,
      apiUrl,
      itemsMenu: [
        {
          key: "ppv-actuals",
          title: "PPV Actuals",
          path: ""
        },
        {
          key: "cost-buckets",
          title: "Maintain List of Cost Buckets",
          path: "/web-analytics/cost-buckets"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
